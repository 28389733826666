import { IApplyThemePayload } from 'services/themes';
import { FlowSteps, WizardFlowTypes } from './models';

// declare all action strings

export const ADVANCE_STEPS = 'wizard/ADVANCE_STEPS';
export const MOUNT_FLOW_STEPS = 'wizard/MOUNT_FLOW_STEPS';
export const REGRESS_STEPS = 'wizard/REGRESS_STEPS';
export const SET_FLOW_STEPS = 'wizard/SET_FLOW_STEPS';
export const SET_WIZARD_FLOW_TYPE = 'wizard/SET_WIZARD_FLOW_TYPE';
export const SET_IS_WIZARD_LOADING = 'wizard/SET_IS_WIZARD_LOADING';
export const RESET_WIZARD_FLOW = 'wizard/RESET_WIZARD_FLOW';
export const SET_CHANNEL_DATA = 'wizard/SET_CHANNEL_DATA';
export const EXECUTE_WIZARD = 'wizard/EXECUTE_WIZARD';

// declare all actions type

export type IWizardAction =
  | IAdvanceStepsAction
  | IMountFlowStepsAction
  | IRegressStepsAction
  | ISetFlowStepsAction
  | ISetWizardFlowTypeAction
  | ISetIsWizardLoadingAction
  | IResetWizardFlowAction
  | ISetChannelDataAction
  | IExecuteWizardAction;

// declare action interfaces

export interface IAdvanceStepsAction {
  type: typeof ADVANCE_STEPS;
}

export interface IMountFlowStepsAction {
  payload: WizardFlowTypes;
  type: typeof MOUNT_FLOW_STEPS;
}

export interface IRegressStepsAction {
  type: typeof REGRESS_STEPS;
}

export interface ISetFlowStepsAction {
  payload: FlowSteps[];
  type: typeof SET_FLOW_STEPS;
}

export interface ISetWizardFlowTypeAction {
  payload: WizardFlowTypes;
  type: typeof SET_WIZARD_FLOW_TYPE;
}

export interface ISetIsWizardLoadingAction {
  payload: boolean;
  type: typeof SET_IS_WIZARD_LOADING;
}

export interface IResetWizardFlowAction {
  type: typeof RESET_WIZARD_FLOW;
}

export interface ISetChannelDataAction {
  payload: IExecuteWizardPayload | undefined;
  type: typeof SET_CHANNEL_DATA;
}

export interface IExecuteWizardAction {
  payload: IApplyThemePayload;
  type: typeof EXECUTE_WIZARD;
}

// declare payload interfaces

export interface IExecuteWizardPayload {
  eventDate?: number;
  name: string;
  subscriptionAmount?: number;
  subscriptionName?: string;
  themeId?: string;
}

// declare action functions

export const advanceSteps = (): IAdvanceStepsAction => ({
  type: ADVANCE_STEPS,
});

export const mountFlowSteps = (
  data: WizardFlowTypes,
): IMountFlowStepsAction => ({
  payload: data,
  type: MOUNT_FLOW_STEPS,
});

export const regressSteps = (): IRegressStepsAction => ({
  type: REGRESS_STEPS,
});

export const setFlowSteps = (data: FlowSteps[]): ISetFlowStepsAction => ({
  payload: data,
  type: SET_FLOW_STEPS,
});

export const setWizardFlowType = (
  data: WizardFlowTypes,
): ISetWizardFlowTypeAction => ({
  payload: data,
  type: SET_WIZARD_FLOW_TYPE,
});

export const setIsWizardLoading = (data: boolean): ISetIsWizardLoadingAction => ({
  payload: data,
  type: SET_IS_WIZARD_LOADING,
});

export const resetWizardFlow = (): IResetWizardFlowAction => ({
  type: RESET_WIZARD_FLOW,
});

export const setChannelData = (
  data?: IExecuteWizardPayload,
): ISetChannelDataAction => ({
  payload: data,
  type: SET_CHANNEL_DATA,
});

export const executeWizardChannelCreation = (data: IApplyThemePayload): IExecuteWizardAction => ({
  payload: data,
  type: EXECUTE_WIZARD,
});
