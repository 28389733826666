import { PLAYLIST_SERVICE_BASE_URL, VIDEO_SERVICE_BASE_URL } from 'config';
import { IPlaylist, IVideo } from 'models';
import maestroApiRequest from 'services/maestro-api-client';

export const upsertVideo = async (
  {
    // front-end-generated id
    newId,
    primaryToken,
    siteId,
    video,
  }: {
    newId?: string;
    primaryToken: string;
    siteId: string;
    video: IVideo;
  },
) => {
  const { _id } = video;
  const { data } = await maestroApiRequest({ primaryToken, siteId })
    .post<IVideo>(
      _id ? `${VIDEO_SERVICE_BASE_URL}/${_id}` : VIDEO_SERVICE_BASE_URL,
      {
        ...video,
        _id: _id || newId,
      },
    );
  return data;
};

export const upsertPlaylist = async (
  {
    playlist,
    primaryToken,
    siteId,
  }: {
    playlist: IPlaylist;
    primaryToken: string;
    siteId: string;
  },
) => {
  const { _id } = playlist;
  const { data } = await maestroApiRequest({ primaryToken, siteId })
    .post(_id ? `${PLAYLIST_SERVICE_BASE_URL}/${_id}` : PLAYLIST_SERVICE_BASE_URL, playlist);
  return data;
};

interface IImportYoutubePlaylistParams {
  primaryToken: string
  siteId: string
  youtubeUrl: string
}

export const importYoutubePlaylist = async (
  { youtubeUrl, siteId, primaryToken }: IImportYoutubePlaylistParams,
): Promise<IPlaylist> => {
  try {
    const { data: playlist } = await maestroApiRequest({
      primaryToken,
      siteId,
    })
      .post(`${PLAYLIST_SERVICE_BASE_URL}/external/import/youtube`, {
        skipInsert: true,
        url: youtubeUrl,
      });

    return playlist;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    throw new Error(`Failed to import youtube playlist: ${error}`);
  }
};
